<template lang="pug">
.landing-nav(
  :class="{ 'fixed' : shouldNavPop }"
)
  .background
  .logo-wrap
    .logo
      img(
        :src="globalMixin_images.logo"
        alt="logo-white.svg"
        @click="globalMixin_scrollTop"
      )
    span.logo-text Digital Insurance Advisor
  .links-buttons-wrap(
    v-if="!isMobileMixin_isMobile"
  )
    .links
      a(
        @click="scrollFunc(0)"
      )
        | Home
      a(
        @click="scrollFunc(1)"
      )
        | How it works
      a(
        @click="scrollFunc(2)"
      )
        | How we're different
      a(
        @click="scrollFunc(3)"
      )
        | Contact
      router-link(
        v-if="isLoggedIn"
        :to="{ name: profilePath }"
      )
        | Profile
    .buttons(
      v-if="!isLoggedIn"
    )
      base-button(
        :label="$t('buttons.startQuoting')"
        @click="onAgentRegister"
        aqua
        class="less-padding"
      )
      base-button(
        class="login"
        :label="$t('buttons.agentLogIn')"
        @click="onAgentLogin"
        noFillColor
      )
      base-button(
        class="agent"
        label="Not and agent?"
        @click="$router.push({ name: 'Landing' })"
        noFillColor
      )
    .buttons(
      v-if="isLoggedIn"
    )
      base-button(
        class="login"
        label="Log out"
        @click="onLogout"
        noFillColor
      )
  burger(
    v-else
    id="hacked"
  )
    .links-buttons-wrap.burger
      .links
        a(
          @click="borgar(0)"
        )
          | Home
        a(
          @click="borgar(1)"
        )
          | How it works
        a(
          @click="borgar(2)"
        )
          | How we're different
        a(
          @click="borgar(3)"
        )
          | Contact
        router-link(
          v-if="isLoggedIn"
          :to="{ name: profilePath }"
        )
          | Profile
      .buttons(
        v-if="!isLoggedIn"
      )
        base-button(
          :label="$t('buttons.startQuoting')"
          @click="onAgentRegister"
          aqua
          class="less-padding"
        )
        base-button(
          class="login"
          :label="$t('buttons.agentLogIn')"
          @click="onAgentLogin"
        )
        base-button(
          class="agent-alt"
          label="Not and agent?"
          noFillColor
          @click="$router.push({ name: 'Landing' })"
        )
      .buttons(
        v-if="isLoggedIn"
      )
        base-button(
          class="login"
          label="Log out"
          @click="onLogout"

        )
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    shouldNavPop: {
      type: Boolean,
    },
  },
  emits: ["open-modal", "logout"],
  computed: {
    ...mapState(["isLoggedIn", "userType"]),
    profilePath() {
      let pathName;

      if (this.userType === 1) return (pathName = "UserProfile");
      if (this.userType === 2) return (pathName = "AgentProfile");

      return pathName;
    },
  },
  methods: {
    onAgentRegister() {
      this.$emit("open-modal", "register");
    },
    onAgentLogin() {
      this.$emit("open-modal", "login");
    },
    onLogout() {
      this.$emit("logout");
    },
    scrollFunc(id) {
      switch (id) {
        case 0:
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          break;
        case 1:
          window.scrollTo({
            top: document.getElementById("how-it-works").offsetTop + 50,
            left: 0,
            behavior: "smooth",
          });
          break;
        case 2:
          window.scrollTo({
            top: document.getElementById("how-we-diff").offsetTop - 55,
            left: 0,
            behavior: "smooth",
          });
          break;
        case 3:
          window.scrollTo({
            top: document.getElementById("footer").offsetTop - 10,
            left: 0,
            behavior: "smooth",
          });
          break;
      }
    },
    borgar(id) {
      // ovo je hakic
      // do not remove unless u want to make things work sussy
      setTimeout(() => {
        document.getElementById("hacked").click();
      }, 50);
      setTimeout(() => {
        this.scrollFunc(id);
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-nav {
  height: $nav-height;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(260.62deg, #5942e6 -0.28%, #3664d9 102.05%);
    opacity: 0;
    z-index: -1;
  }
  &.fixed {
    padding-right: min(12vw, 12em - 20px);
    padding-left: min(12vw, 12em);
    position: fixed;
    top: -2.5em;
    // uncomment for gap
    // top: 0;
    left: 0;
    z-index: 2;
    transition: position 0.3s ease-out;
    margin-top: 2em;
    .background {
      transition: opacity 0.3s ease-out;
      opacity: 1;
    }
  }
  .logo {
    img {
      cursor: pointer;
      width: 9.5em;
    }
  }
  .logo-text {
    margin-top: 5px;
    color: white;
  }
  .links-buttons-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // gap: 4em;
    @include flex-gap(4em, "row wrap");
    //in-burger styles
    &.burger {
      margin-top: 0;
      background: linear-gradient(260.62deg, #5942e6 -0.28%, #3664d9 102.05%);
      padding: 5vw;
      // gap: 4vw;
      border-radius: 6px;
      * {
        font-size: 1.2em;
      }
      .links {
        flex-grow: 1;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
      }
      .buttons {
        flex-direction: column;
        @include flex-gap(1em, "column wrap");
      }
    }
    //in-burger styles
    .links {
      display: flex;
      flex-wrap: wrap;
      // gap: 3em;
      @include flex-gap(3em, "row wrap");
      margin-right: 1em;
      a {
        text-decoration: none;
        cursor: pointer;
        color: #fff;
        font-weight: 600;
      }
    }
    .buttons {
      display: flex;
      // gap: 1.2em;
      @include flex-gap(1.2em, "row nowrap");
      > * {
        width: 9em;
      }
      .login {
        border: 2px solid #fff;
        color: #fff;
      }
      .less-padding {
        padding: 0.8em 0.5em;
      }
      .agent {
        padding: 0.8em 0.5em;
        border: 2px solid rgba($color: #fff, $alpha: 1);
        color: #fff;
      }
      .agent-alt {
        padding: 0.8em 0.5em;
        border: 2px solid $main-blue;
        color: $main-blue;
      }
    }
  }
}

@media (max-width: 1160px) {
  .landing-nav {
    &.fixed {
      padding-left: 3em;
      padding-right: 3em;
    }
    .links-buttons-wrap {
      .links {
        margin-right: 1.65vw !important;
        @include flex-gap(1.65vw, "row nowrap");
      }
    }
  }
}

@media (max-width: 820px) {
  .landing-nav {
    flex-direction: column;
    flex-wrap: unset;
    // gap: 1em;
    &.fixed {
      flex-direction: row;
      flex-wrap: wrap;
      // gap: 0;
      padding-left: 1em;
      padding-right: 1em;
    }
    .links-buttons-wrap {
      // gap: 1.3vw;
      .links {
        // gap: 1.3vw;
        @include flex-gap(3vw, "row nowrap");
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-nav {
    flex-direction: row;
    &.fixed {
      padding-left: 4em;
      padding-right: 4em;
    }
    .links-buttons-wrap {
      background: #f8f9fc !important;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // gap: 1.65vw;
      .links {
        // gap: 1.65vw;
        flex-grow: unset !important;
        a {
          display: inline-block;
          margin: 10px 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 155.8%;
          text-align: center;
          letter-spacing: -0.02em;
          color: #434961;
          // &.router-link-active {
          //   color: $main-blue;
          // }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .landing-nav {
    &.fixed {
      padding-left: 2em;
      padding-right: 2em;
    }
  }
}
</style>
